<template>
  <div class="page-bg">
    <el-carousel>
      <el-carousel-item>
        <div class="intro-text mobile_page-container">
<!--          <h2>{{ item.info1 }}</h2>-->
<!--          <h3>{{ item.info2 }}</h3>-->
        </div>
      </el-carousel-item>
<!--      <el-carousel-item>-->
<!--        <div class="intro-text intro-text1 mobile_page-container"></div>-->
<!--      </el-carousel-item>-->
    </el-carousel>
    <div class="pro-container pro-left move-wrap">
      <div class="pro-intro">
        <h4>Product ＆ Services</h4>
        <h5>自主開発の先端AI技術を活用し、教育、医療、金融など多くの業界に革新的サービスを提供し、客様がより大きな価値を実現できるよう支援します。</h5>
      </div>
    </div>
<!--    <div class="pro-container pro-infos">-->
<!--      <div class="pro-img img-left">-->
<!--        <p>-->
<!--          <img src="~@/assets/images/index_mobile/LingoMate.png">-->
<!--        </p>-->
<!--      </div>-->
<!--      <div class="pro-info right-info no-left">-->
<!--        <p class="pro-name">APP: Lingo Mate</p>-->
<!--        <p class="pro-intros">非常にプロフェッショナルで面白いAIバーチャル英会話アプリです。<br/>日本語と英語音声認識および合成技術に加え、言語教育に特化した最先端の対話生成AIエンジンも搭載され、実際の英語会話シーンを完全にシミュレートできます。<br/>Lingo Mateは本物の英語講師に代わって、あなたに時間、場所問わず楽しく効率的に英語力を向上させることを実現できます。</p>-->
<!--        <div class="more-btn" >-->
<!--          <div class="btn-text">-->
<!--            <span ref="appText1"><a style="color:rgba(255, 255, 255, 1);" :href="$lingomate" target="_blank">Detail</a></span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="pro-container pro-infos pro-infos1">
      <div class="pro-img img-left1">
        <p>
          <img src="~@/assets/images/index_mobile/AI_eng.png">
        </p>
      </div>
      <div class="pro-info pro-info1 right-info no-right1">
        <p class="pro-name">Platform: Dolphin AI Cloud</p>
        <p class="pro-intros">開発向け音声認識&合成、対話モジュールなどのCloud APIサービスです。<br/>自社開発の日英中など20以上言語対応の音声認識＆合成＆対話エンジンをCloud APIサービスとして提供し、開発者が機械学習のような専門技術を知らなくても高精度の音声対話アプリケーションを開発することができる。 各種APIは様々な開発言語に対応していて、On-Premise構成でも相談可能です。</p>
        <div class="more-btn"  @click="openPage('voice')">
          <div class="btn-text">
            <span ref="engineText1" @click="openPage('voice')">Detail</span>
          </div>
        </div>
      </div>
    </div>
    <div class="pro-container pro-infos">
      <div class="pro-img img-left2">
        <p>
          <img src="~@/assets/images/index_mobile/dlp_AI.png">
        </p>
      </div>
      <div class="pro-info pro-info1 right-info no-right">
        <p class="pro-name">Product: Smart Oral Evaluation</p>
        <p class="pro-intros">英語発音自動評価システムです。<br/>先生など属人的な英語会話評価は主観性強く、時間がかかり、効率が悪いといった欠点があり、発音評価システム採用より、生徒たち英語勉強の効率化と成果の可視化を根本的に変えることができます。当システムは教育のDX化＆AI導入の強力なツールと大きく期待できます。</p>
        <div class="more-btn"  @click="openPage('soe')">
          <div class="btn-text">
            <span ref="dolphinText1" @click="openPage('soe')">Detail</span>
          </div>
        </div>
      </div>
    </div>
    <div class="pro-container pro-left1 last-intro">
      <div class="pro-intro1">
        <h4>Brand Story</h4>
        <h5><p>Dolphin（ドルフィン）は、可愛く社交的でありながら遠海の広い場所において活動する海洋動物でございます。</p><br/>
          <p>ドルフィンは、頭がよく、音声の探知能力が抜群で、音声コミュニケーション能力と認知能力を生かせながら、素晴らしい芸が演じられ、人類に非常に愛されております。</p><br/>
          <p>ドルフィンの学習能力と社会的機能をヒントに、我が会社を「Dolphin AI」と名付けました。</p></h5>
        <div class="more-btn"  @click="openPage('/company?id=Weare')">
          <div class="btn-text">
            <span ref="companyText1" @click="openPage('/company')">Detail</span>
          </div>
        </div>
      </div>
    </div>
    <div class="zhuanli">
      <h4>Data Security</h4>
      <div>
        <p><img src="@/assets/images/index/Security1.png" alt=""><span>ISO 27001</span></p>
        <p><img src="@/assets/images/index/security2.svg" alt=""><span>SSL/TLS</span></p>
        <p><img src="@/assets/images/index/security3.svg" alt=""><span>GDPR</span></p>
        <p><img src="@/assets/images/index/security4.svg" alt=""><span>APPI</span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data () {
    return {
      bannerList: [
        {
          info1: 'Dolphin AI',
          info2: 'AIで業界へのイノベーションと顧客への価値あるサービス'
        },
        // {
        //   info1: 'Lingo Mate',
        //   info2: '最高のAI英会話練習パートナー'
        // }
      ]
    }
  },
  methods: {
    openPage (url) {
      // this.$router.push(url)
      if (url === 'app') {
        window.open(this.$lingomate, '_blank')
      } else if (url === 'soe') {
        window.open(this.$dolphinsoe, '_blank')
      } else if (url === 'voice') {
        window.open(this.$dolphinvoice, '_blank')
      } else {
        this.$router.push(url)
      }
    },
  }
}
</script>

<style lang="less" scoped>
.el-carousel {
  color: #fff;
  margin-bottom: 24px;
  /deep/ .el-carousel__container {
    height: 165px!important;
  }
  /deep/ .el-carousel__indicator .el-carousel__button {
    width: 5.5px;
    height: 5.5px;
    border-radius: 8px;
  }
  .el-carousel__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .intro-text {
      flex: 1;
      height: 100%;
      background: url('../assets/images/index_mobile/index_banner1_mobile.jpg') no-repeat left top;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 40.54px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        margin-bottom: 15px;
      }
      h3 {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 17.38px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
      }
      h2, h3 {
        width: 182px;
      }
    }
    .intro-text1 {
      background: url('../assets/images/index_mobile/index_banner2_mobile.jpg') no-repeat left top;
      background-size: 100% 100%;
    }
  }
}
.pro-container {
  min-height: 170px;
  position: relative;
  display: flex;
  justify-content: space-between;
  .pro-intro {
    padding-left: 28px;
    padding-right:28px;
    padding-bottom:29.09px;
    h4 {
      left: 28px;
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0px;
      color: rgba(47, 41, 223, 1);
      text-align: left;
      vertical-align: top;
      margin-bottom: 16px;
    }
    h5 {
      left: 28px;
      top: 380px;
      height: 115px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 2px;
      line-height: 28px;
      color: rgba(60, 60, 60, 1);
      text-align: left;
      vertical-align: top;
    }
  }
  .pro-intro1 {
    width: 334px;
    margin: 0 auto;
    h4 {
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 37.5px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      vertical-align: top;
      margin-bottom: 24px;
      margin-top:34px;
    }
    h5 {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 28px;
      color: rgba(255, 255, 255, 1);
      text-align: left;
      vertical-align: top;
    }
  }
  .pro-img {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 20px;
    overflow: hidden;
    margin-left: 78px;
    img {
      width: 479px;
      transform: scale(2.1);
    }
  }
  .pro-img.scroll-top >img {
    transform: scale(1.6);
    transition: transform 0.5s linear;
  }
  .pro-img.scroll-bottom >img {
    transform: scale(2.1);
    transition: transform 0.5s linear;
  }
  .move-text {
    font-size: 88px;
    font-weight: 300;
    letter-spacing: 0px;
    color: rgba(68, 82, 240, 1);
    text-align: left;
    vertical-align: top;
    white-space: nowrap;
    width: fit-content;
  }
  .move-text-container {
    height: 116px;
    width: 100%;
    position: absolute;
    left: -10px;
    right: 0;
    bottom: 140px;
  }
}
.move-wrap .pro-img{
  border-radius: 20px 0 0 20px;
}
.move-wrap {
  overflow: hidden;
}
.last-intro {
  padding-bottom: 83.8px;
  margin-top: 43.8px;
  margin-bottom: 0;
  .pro-img {
    border-radius: 0;
    img {
      transform: scale(1);
    }
  }
  .more-btn {
    margin-top: 32px;
  }
}
.zhuanli {
  width: 100%;
  margin-bottom: 30px;
  background: #fff;
  text-align: center;
  h4 {
    padding-top: 35px;
    margin-bottom: 35px;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    color: rgba(47, 41, 223, 1);
    text-align: center;
    vertical-align: top;
  }
  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      display: flex;
      flex-direction: column;
      justify-content: center;
      span {
        margin-top: 5px;
        font-size: 12px;
        line-height: 16px;
        color: rgba(120, 127, 132, 1);
      }
    }
    img {
      width: 70px;
      height: 70px;
      margin: 0 10px;
    }
  }
}
.pro-left {
  //padding-left: 28px;
  background: url('../assets/images/grid_bg.png') no-repeat -467px -51px;
  background-size: 100% 100%;
  .pro-img {
    right: 0;
  }
}
.pro-left1 {
  //padding-left: 28px;
  background: url('../assets/images/index_mobile/Introduce.jpg') no-repeat;
  background-size: 100% 100%;
  .pro-img {
    right: 0;
  }
}
.pro-infos {
  display: block;
  background-size: 100% 100%;
  .pro-intro {
    position: absolute;
    right: 165px;
  }
  .pro-img {
    height: 121px;
    flex-shrink: 0;
    background: url('../assets/images/index_mobile/pro_img_bg.jpg') no-repeat -150px top;
    border-radius: 0;
    img {
      transform: scale(1);
      width: 100%;
      height: 100%;
    }
  }
  .pro-img.scroll-top img {
    transform: scale(1);
    transition: transform 0.5s linear;
  }
  .pro-img.scroll-bottom img {
    transform: scale(1);
    transition: transform 0.5s linear;
  }
  .right-img {
    background-position: 100px 0;
    position: relative;
    >div {
      position: absolute;
      right: -2px;
      top: 0;
      overflow: hidden;
      width: 724px;
      height: 483px;
    }
  }
}
.pro-infos1 {
  margin-bottom: 43.8px;
  //margin-top: 44.8px;
  overflow: hidden;
}
.pro-info {
  width: 334px;
  top: 90px;
  min-height: 100px;
  background: url('../assets/images/index_mobile/grid_bg.png') no-repeat -164px 74px;
  background-size: 172% 96%;
}
.pro-info1 {
  background: none;
}
.right-info {
  flex: 1;
}
.right-info.no-left {
  padding-top: 87px;
  margin: auto;
}
.right-info.no-right {
  padding-top: 82px;
  margin: auto;
}
.right-info.no-right1 {
  padding-top: 62px;
  margin: auto;
}
.pro-name {
  background: url('../assets/images/pro_name.png') no-repeat left 5px;
  background-size: 24px 24px;
  padding-left: 30px;
  padding-top:6px;
  color: #2C2FE4;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23.44px;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 16px;
}
.pro-intros {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 28px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
  margin-bottom: 16px;
}
.more-btn {
  padding-left: 20px;
  padding-right: 20px;
  height: 43.2px;
  text-align: center;
  opacity: 1;
  border-radius: 20px;
  background: linear-gradient(225deg, rgba(87, 120, 255, 1) 0%, rgba(47, 41, 223, 1) 100%);
  cursor: pointer;
  cursor: hand;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  span {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 21.1px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    vertical-align: middle;
  }
  .btn-image {
    overflow: hidden;
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    display: flex;
  }
  p, p>img {
    width: 22px;
    height: 22px;
  }
  .btn-image >p:nth-of-type(1) {
    transform: translateX(-22px);
  }
  .btn-image >p:nth-of-type(2) {
    transform: translateX(-22px);
  }
  .btn-image .btn-move-enter {
    transform: translateX(0)!important;
    transition: transform 0.5s linear;
  }
  .btn-image .btn-move-leave {
    transform: translateX(-22px)!important;
    transition: transform 0.5s linear;
  }
}
.pro-container .img-left {
  position: relative;
  overflow: visible!important;
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-top: 80px;
  >p {
    top: -80px;
    width: 269px;
    height: 270px;
    position: absolute;
  }
  >div {
    position: absolute;
    left: 0;
    top: 80px;
    overflow: hidden;
    width: 682px;
    height: 455px;
  }
}
.pro-container .img-left1 {
  position: relative;
  overflow: visible!important;
  margin-left: 0;
  margin-top: 48px;
  >p {
    top: -50px;
    width: 285.07px;
    position: absolute;
    margin-right: 0;
    overflow: hidden;
  }
  >div {
    position: absolute;
    left: 0;
    top: 80px;
    overflow: hidden;
    width: 682px;
    height: 455px;
  }
}
.pro-container .img-left2 {
  position: relative;
  overflow: visible!important;
  margin-left: 0;
  margin-top: 98.8px;
  >p {
    top: -50px;
    width: 303px;
    height: 202.1px;
    position: absolute;
  }
  >div {
    position: absolute;
    left: 0;
    top: 80px;
    overflow: hidden;
    width: 682px;
    height: 455px;
  }
}
.move-text-img {
  height: 575px;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.scroll-white-text {
  height: 120px;
}
.scroll-white-div {
  width: 100%;
  height: 120px;
  position: absolute;
  bottom: 0;
  white-space: nowrap;
  left: -958px;
  >p {
    font-size: 88px;
    color: #fff;
    font-weight: 300;
    width: fit-content;
  }
}
// Detail
.text-move-enter {
  transform: translateY(-54px);
  transition: transform 0.5s linear;
}
.text-move-leave {
  transform: translateY(0);
  transition: transform 0.5s linear;
}
.scroll-text {
  animation: scroll 25s linear infinite;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
</style>
