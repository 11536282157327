var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-bg"},[_c('el-carousel',[_c('el-carousel-item',[_c('div',{staticClass:"intro-text mobile_page-container"})])],1),_vm._m(0),_c('div',{staticClass:"pro-container pro-infos pro-infos1"},[_vm._m(1),_c('div',{staticClass:"pro-info pro-info1 right-info no-right1"},[_c('p',{staticClass:"pro-name"},[_vm._v("Platform: Dolphin AI Cloud")]),_vm._m(2),_c('div',{staticClass:"more-btn",on:{"click":function($event){return _vm.openPage('voice')}}},[_c('div',{staticClass:"btn-text"},[_c('span',{ref:"engineText1",on:{"click":function($event){return _vm.openPage('voice')}}},[_vm._v("Detail")])])])])]),_c('div',{staticClass:"pro-container pro-infos"},[_vm._m(3),_c('div',{staticClass:"pro-info pro-info1 right-info no-right"},[_c('p',{staticClass:"pro-name"},[_vm._v("Product: Smart Oral Evaluation")]),_vm._m(4),_c('div',{staticClass:"more-btn",on:{"click":function($event){return _vm.openPage('soe')}}},[_c('div',{staticClass:"btn-text"},[_c('span',{ref:"dolphinText1",on:{"click":function($event){return _vm.openPage('soe')}}},[_vm._v("Detail")])])])])]),_c('div',{staticClass:"pro-container pro-left1 last-intro"},[_c('div',{staticClass:"pro-intro1"},[_c('h4',[_vm._v("Brand Story")]),_vm._m(5),_c('div',{staticClass:"more-btn",on:{"click":function($event){return _vm.openPage('/company?id=Weare')}}},[_c('div',{staticClass:"btn-text"},[_c('span',{ref:"companyText1",on:{"click":function($event){return _vm.openPage('/company')}}},[_vm._v("Detail")])])])])]),_vm._m(6)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pro-container pro-left move-wrap"},[_c('div',{staticClass:"pro-intro"},[_c('h4',[_vm._v("Product ＆ Services")]),_c('h5',[_vm._v("自主開発の先端AI技術を活用し、教育、医療、金融など多くの業界に革新的サービスを提供し、客様がより大きな価値を実現できるよう支援します。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pro-img img-left1"},[_c('p',[_c('img',{attrs:{"src":require("@/assets/images/index_mobile/AI_eng.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"pro-intros"},[_vm._v("開発向け音声認識&合成、対話モジュールなどのCloud APIサービスです。"),_c('br'),_vm._v("自社開発の日英中など20以上言語対応の音声認識＆合成＆対話エンジンをCloud APIサービスとして提供し、開発者が機械学習のような専門技術を知らなくても高精度の音声対話アプリケーションを開発することができる。 各種APIは様々な開発言語に対応していて、On-Premise構成でも相談可能です。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pro-img img-left2"},[_c('p',[_c('img',{attrs:{"src":require("@/assets/images/index_mobile/dlp_AI.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"pro-intros"},[_vm._v("英語発音自動評価システムです。"),_c('br'),_vm._v("先生など属人的な英語会話評価は主観性強く、時間がかかり、効率が悪いといった欠点があり、発音評価システム採用より、生徒たち英語勉強の効率化と成果の可視化を根本的に変えることができます。当システムは教育のDX化＆AI導入の強力なツールと大きく期待できます。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('p',[_vm._v("Dolphin（ドルフィン）は、可愛く社交的でありながら遠海の広い場所において活動する海洋動物でございます。")]),_c('br'),_c('p',[_vm._v("ドルフィンは、頭がよく、音声の探知能力が抜群で、音声コミュニケーション能力と認知能力を生かせながら、素晴らしい芸が演じられ、人類に非常に愛されております。")]),_c('br'),_c('p',[_vm._v("ドルフィンの学習能力と社会的機能をヒントに、我が会社を「Dolphin AI」と名付けました。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zhuanli"},[_c('h4',[_vm._v("Data Security")]),_c('div',[_c('p',[_c('img',{attrs:{"src":require("@/assets/images/index/Security1.png"),"alt":""}}),_c('span',[_vm._v("ISO 27001")])]),_c('p',[_c('img',{attrs:{"src":require("@/assets/images/index/security2.svg"),"alt":""}}),_c('span',[_vm._v("SSL/TLS")])]),_c('p',[_c('img',{attrs:{"src":require("@/assets/images/index/security3.svg"),"alt":""}}),_c('span',[_vm._v("GDPR")])]),_c('p',[_c('img',{attrs:{"src":require("@/assets/images/index/security4.svg"),"alt":""}}),_c('span',[_vm._v("APPI")])])])])
}]

export { render, staticRenderFns }